<template>
  <div v-if="order !== ''">
    <PageTitle
      title="訂單列表"
      icon="chevron_left"
      btn="取消訂單"
      @btnClick="orderCancelDialog = true"
      @iconClick="$router.go(-1)"
    />

    <EcommerceOrderInfoBlock title="訂單資訊" hideBtn>
      <section v-if="order !== ''" class="order-info-wrapper">
        <FormInfo gap="10" class="order-info info">
          <FormInfoItem
            width="120"
            title="會員姓名"
            :content="order.Member ? order.Member.UserInfo.name : '' "
          />
          <FormInfoItem
            width="120"
            title="訂單金額"
            :content="`$ ${order.totalPrice}`"
          />
          <FormInfoItem
            width="120"
            title="訂單狀態"
            :content="orderStatusDict(order.status, order.cancelBy)"
          />
        </FormInfo>
        <FormInfo gap="10" class="order-info info">
          <FormInfoItem
            width="120"
            title="訂單編號"
            :content="order.code"
          />
          <FormInfoItem
            width="120"
            title="訂單成立時間"
            :content="createAt"
          />
          <FormInfoItem
            v-if="order.EcommerceOrderAddCashback"
            width="120"
            title="回饋金狀態"
          >
            <BaseElSelect
              v-model="formData.rebateStatus"
              class="status-select"
              :disabled="formData.rebateStatus === 'cancel'"
              @change="updateRebateStatus"
            >
              <BaseElSelectOption
                v-for="status in rebateStatusOptions"
                :key="status.value"
                :label="status.name"
                :value="status.value"
                :disabled="status.disabled"
              />
            </BaseElSelect>
          </FormInfoItem>
        </FormInfo>
      </section>
    </EcommerceOrderInfoBlock>

    <EcommerceOrderInfoBlock v-if="displayCustomFlowConfig.length" title="自定義流程" hideBtn>
      <section class="p-[20px]">
        <CustomFlowArea
          :orderId="order.id"
          :customFlowConfig="customFlowConfig"
          :record="orderCustomFlowRecord"
          @refresh="refresh"
        />
      </section>
    </EcommerceOrderInfoBlock>

    <EcommerceOrderInfoBlock v-loading="paymentLoading" title="付款資訊" hideBtn>
      <section v-if="order !== ''" class="order-info-wrapper">
        <FormInfo gap="10" class="order-info info">
          <FormInfoItem
            width="120"
            title="付款方式"
            :content="
              orderPaymentConfig
                ? paymentTypes(orderPaymentConfig.type)
                : '免付款'
            "
          />
          <FormInfoItem width="120" title="付款狀態">
            <BaseElSelect
              v-model="formData.paymentStatus"
              class="status-select"
              placeholder="選擇狀態"
              :disabled="
                paymentStatusDisabled
              "
              @change="updatePaymentStatus()"
            >
              <BaseElSelectOption
                v-for="status in paymentStatus"
                :key="status.value"
                :disabled="status.value !== availablePaymentStatus()"
                :label="status.name"
                :value="status"
              />
            </BaseElSelect>
          <!-- <el-tag>{{ paymentStatusDict(order.EcommercePaymentRecord.status).name }}</el-tag> -->
          </FormInfoItem>
          <FormInfoItem
            width="120"
            title="儲值金"
            :content="order.walletPrice ? `$${order.walletPrice}` : '$0'"
          />
          <FormInfoItem
            width="120"
            :title="externalWalletName"
            :content="order.externalWalletPrice ? `$${order.externalWalletPrice}` : '$0'"
          />
          <FormInfoItem v-if="showRefundedStatus" width="120" title="退款狀態">
            <BaseElSelect
              v-model="formData.refundedStatus"
              :disabled="isRefunded"
              class="status-select"
              placeholder="選擇狀態"
              @change="changeRefundedStatus"
            >
              <BaseElSelectOption
                v-for="status in refundedStatusConfig"
                :key="status.value"
                :disabled="disabledRefundOptions(status.value)"
                :label="status.name"
                :value="status"
              />
            </BaseElSelect>
          </FormInfoItem>
        </FormInfo>
        <FormInfo gap="10" class="order-info info">
          <!-- <FormInfoItem
            width="120"
            title="付款編號"
            :content="paymentRecord.code"
          /> -->
          <template v-if="orderPaymentConfig">
            <FormInfoItem
              v-if="orderPaymentConfig.type === 'atm'"
              width="120"
              title="回覆狀態"
              :content="
                order.EcommercePaymentRecord.EcommercePaymentRecordUserResponse
                  ? '已回覆'
                  : '未回覆'
              "
            />
            <FormInfoItem
              v-if="
                order.EcommercePaymentRecord.EcommercePaymentRecordUserResponse
                  &&
                  orderPaymentConfig.type === 'atm'
              "
              width="120"
              title="回覆資訊"
              :content="
                order.EcommercePaymentRecord.EcommercePaymentRecordUserResponse
                  .atmLastCode !== null
                  ? `匯款末五碼：${order.EcommercePaymentRecord.EcommercePaymentRecordUserResponse.atmLastCode} `
                  : '' +
                    order.EcommercePaymentRecord
                      .EcommercePaymentRecordUserResponse.name !==
                    null
                    ? `匯款人戶名：${order.EcommercePaymentRecord.EcommercePaymentRecordUserResponse.name}`
                    : ''
              "
            />
          </template>

          <template
            v-if="
              orderPaymentConfig
                ? needShowPaymentCodeTypes.includes(orderPaymentConfig.type)
                : false
            "
          >
            <FormInfoItem
              width="120"
              title="付款編號"
              :content="order.EcommercePaymentRecord.code"
            />
          </template>
          <FormInfoItem
            width="120"
            title="付款金額"
            :content="`$${order.paymentPrice}`"
          />

          <FormInfoItem width="120" title="儲值金狀態">
            <BaseElSelect
              v-model="formData.walletStatus"
              :disabled="
                walletStatusDict(order.EcommerceOrderWalletRecord).value ===
                  'refunded' || !order.EcommerceOrderWalletRecord
              "
              class="status-select"
              placeholder="選擇狀態"
              @change="walletStatusUpdate"
            >
              <BaseElSelectOption
                v-for="status in walletStatus"
                :key="status.value"
                :label="status.name"
                :value="status"
                :disabled="status.disabled"
              />
            </BaseElSelect>
          </FormInfoItem>
          <FormInfoItem width="120" :title="`${externalWalletName}狀態`">
            <BaseElSelect
              v-model="formData.externalWalletStatus"
              :disabled="!order.externalWalletPaidRecordId || order.externalWalletRefundRecordId"
              class="status-select"
              placeholder="選擇狀態"
              @change="externalWalletStatusUpdate"
            >
              <BaseElSelectOption
                v-for="status in walletStatus"
                :key="status.value"
                :label="status.name"
                :value="status"
                :disabled="status.disabled"
              />
            </BaseElSelect>
          </FormInfoItem>
        </FormInfo>
      </section>
    </EcommerceOrderInfoBlock>

    <!-- <RebateInfo hideBtn title="回饋金資訊" /> -->

    <EcommerceOrderInfoBlock v-loading="deliveryLoading" title="運送資訊" @edit="showDialog = true">
      <DeliveryInfo
        v-if="order !== ''"
        :delivery="delivery"
        :order="order"
        class="order-info-wrapper"
        :status="formData.deliveryStatus"
        :orderCancel="isCanceled"
        @update:status="changeDeliveryStatus"
        @donwload="onDownloadPickupSheet"
      />
    </EcommerceOrderInfoBlock>

    <!-- 發票資訊 -->
    <section v-loading="invoiceLoading">
      <InvoiceInfo
        :invoice="invoice"
        :orderId="order.id"
        :config="config"
        :isCanceled="isCanceled"
        @updated="refresh"
        @loading="invoiceLoading = true"
        @loaded="invoiceLoading = false"
      />
    </section>
    <!-- 備註資訊 -->
    <EcommerceOrderInfoBlock title="備註資訊" @edit="noteEditDialog = true">
      <section>
        <FormInfo gap="10" class="order-info info">
          <div class="flex justify-between">
            <FormInfoItem width="150" title="消費者備註">{{ order.EcommerceOrderNoteMember? order.EcommerceOrderNoteMember.text : '' }}</FormInfoItem>
          </div>
          <FormInfoItem width="150" title="告知消費者備註">{{ order.EcommerceOrderNoteShop? order.EcommerceOrderNoteShop.text : '' }}</FormInfoItem>
          <FormInfoItem width="150" title="店家內部備註">{{ order.EcommerceOrderNoteAdmin? order.EcommerceOrderNoteAdmin.text : '' }}</FormInfoItem>
        </FormInfo>
      </section>
    </EcommerceOrderInfoBlock>

    <p style="margin-top: 28px; margin-bottom: 16px; color: var(--primary-100); font-size: 18px; font-weight: 500; padding-left: 16px;">訂購商品資訊</p>

    <BaseTable
      v-loading="loading"
      :data="order.EcommerceOrderProducts"
      empty-text="暫無數據"
    >
      <EmptyBlock slot="empty" />
      <BaseElTableColumn prop="name" label="圖片" align="center">
        <template slot-scope="scope">
          <img
            style="margin: auto;"
            :width="`${imgSize}px`"
            :src="imgSource(scope.row.MainImage)"
            alt=""
          >
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="名稱" prop="name" align="center" />
      <BaseElTableColumn label="規格" prop="specName" align="center" />
      <BaseElTableColumn label="原價格" prop="productPrice" align="center" />
      <BaseElTableColumn label="優惠價" prop="price" align="center" />
      <BaseElTableColumn label="數量" prop="quantity" align="center" />
      <BaseElTableColumn label="小計" prop="totalPrice" align="center" />
    </BaseTable>

    <CalculateInfo :order="order" />

    <!-- 提醒 Dialog -->
    <AutoInvoiceDialog
      v-if="autoInvoiceDialog"
      @close=";(autoInvoiceDialog = false), refresh()"
      @confirm="autoInvoice"
      @cancel="updateDeliveryStatus"
    />
    <OrderCancelDialog
      v-if="orderCancelDialog"
      @close="orderCancelDialog = false"
      @cancelOrder="cancelOrder"
    />
    <ShipSettingDialog
      :show.sync="showDialog"
      :delivery="delivery"
      @confirm="updateDeliveryInfo"
    />
    <ShipWarningDialog
      v-if="shipWarningDialog"
      @close="shipWarningDialog = false"
      @confirm="updateDeliveryStatus"
    />
    <NoteEditDialog v-if="noteEditDialog" :order="order" @close="noteEditDialog = false" @confirm="refresh" />
  </div>
</template>
<script>

import NoteEditDialog from '@/views/Ecommerce/Dialog/NoteEditDialog.vue'
import ShipWarningDialog from '@/views/Ecommerce/Dialog/ShipWarningDialog.vue'
import OrderCancelDialog from '@/views/Ecommerce/Dialog/OrderCancelDialog.vue'
import AutoInvoiceDialog from '@/views/Ecommerce/Dialog/AutoInvoiceDialog.vue'
import ShipSettingDialog from '@/views/Ecommerce/Dialog/ShipSettingDialog.vue'
import FormInfo from '@/components/FormInfo/FormInfo.vue'
import FormInfoItem from '@/components/FormInfo/FormInfoItem.vue'
import EcommerceOrderInfoBlock from './components/OrderInfoBlock.vue'
import CustomFlowArea from '@/components/CustomFlowArea.vue'
import InvoiceInfo from './components/InvoiceInfo.vue'
import DeliveryInfo from './components/DeliveryInfo.vue'
// import RebateInfo from './components/RebateInfo.vue'
import CalculateInfo from './components/CalculateInfo.vue'

import {
  FindOrder,
  UpdatePaymentStatus,
  UpdateDeliveryStatus,
  UpdateDeliveryInfo,
  AutoRefunded,
  WalletRecordRefunded,
  ExternalWalletRefunded,
  CancelOrder,
  UpdateRefundedStatus,
  UpdateInvoiceStatus,
  GenerateOrderPickupSheet,
  UpdatePprocessAddCashback,
} from '@/api/ecommerce/order'
import { onMounted } from 'vue'
import { mapGetters } from 'vuex'
import { imgSrc } from '@/utils/helper'
import { map, reduce, filter, find, get } from 'lodash'
import {
  orderStatusConfig,
  paymentStatusConfig,
  paymentConfig,
  orderDeliveryStatus,
  orderWalletRecordStatusConfig,
  refundedStatusConfig,
  cancelByKeysConfig,
} from '@/config/ecommerce'
import { rebateStatusConfig } from '@/config/rebate'
import dayjs from '@/lib/dayjs'
import { CreateFormPost } from '@/utils/FormPost'
import { useCustomFlow } from '@/use/useCustomFlow'
import { useExternalWallet } from '@/use/useExternalWallet'

export default {
  name: 'EcommerceOrderDetailWrapper',
  components: {
    FormInfo,
    FormInfoItem,
    InvoiceInfo,
    AutoInvoiceDialog,
    OrderCancelDialog,
    ShipWarningDialog,
    NoteEditDialog,
    EcommerceOrderInfoBlock,
    DeliveryInfo,
    ShipSettingDialog,
    // RebateInfo,
    CalculateInfo,
    CustomFlowArea,
  },
  props: ['config'],
  setup (props, { emit }) {
    const { customFlowConfig, displayCustomFlowConfig, getCustomFlowConfig, useCustomFlowFeature } = useCustomFlow('ecommerce')
    const { externalWalletName, getExternalWalletConfig } = useExternalWallet()
    onMounted(() => {
      getExternalWalletConfig()
    })
    return { customFlowConfig, getCustomFlowConfig, displayCustomFlowConfig, useCustomFlowFeature, externalWalletName }
  },
  data: () => ({
    paymentLoading: false,
    deliveryLoading: false,
    refundedStatusConfig,
    autoInvoiceDialog: false,
    orderCancelDialog: false,
    shipWarningDialog: false,
    noteEditDialog: false,
    order: '',
    loading: false,
    showDialog: false,
    invoiceLoading: false,
    imgSize: 90,
    paymentWhiteList: ['unpaid', 'adminPaid', 'adminRefunded'],
    needShowPaymentCodeTypes: ['jkos', 'newebpay'],
    orderStatus: orderStatusConfig,
    paymentStatus: paymentStatusConfig,
    walletStatus: orderWalletRecordStatusConfig,
    cancelByKeysConfig,
    formData: {
      paymentStatus: '',
      walletStatus: '',
      deliveryStatus: '',
      deliveryCode: '',
      refundedStatus: '',
      rebateStatus: '',
    },
  }),
  computed: {
    ...mapGetters(['shop']),
    paymentStatusDisabled () {
      return this.formData.paymentStatus?.value === 'adminRefunded' || this.isCanceled
    },
    orderCustomFlowRecord () {
      return get(this.order, 'CustomFlowRecords') || []
    },
    invoice () {
      if (this.order === '' || !this.order.EcommerceOrderInvoice) return {}
      return this.order.EcommerceOrderInvoice
    },
    // ANCHOR 運送資訊
    delivery () {
      if (this.order === '' || !this.order.EcommerceOrderDelivery) return {}
      return this.order.EcommerceOrderDelivery
    },
    showRefundedStatus () {
      // 已付款
      const paidStatus = ['systemPaid', 'adminPaid']
      const paid = paidStatus.includes(this.formData.paymentStatus?.value)

      // 已退款 || 申請退款中
      const refundStatus = ['autoRefunded', 'adminRefunded', 'request']
      const refunded = refundStatus.includes(
        this.order.EcommercePaymentRecord.refundedStatus,
      )

      return paid && refunded
    },

    isRequestRefund () {
      if (!this.order.EcommercePaymentRecord) return false
      return this.order.EcommercePaymentRecord.refundedStatus === 'request'
    },

    isRefunded () {
      const refundStatus = ['autoRefunded', 'adminRefunded']
      const refunded = refundStatus.includes(
        this.order.EcommercePaymentRecord.refundedStatus,
      )
      return refunded
    },

    isCanceled () {
      const whitList = [
        'adminCancel',
        'systemCancel',
        'clientCancel',
        'cancel',
        'requestRefunded',
      ]
      return whitList.includes(this.order.status)
    },

    paymentRecord () {
      if (!this.order) return {}
      return this.order.EcommercePaymentRecord
    },

    createAt () {
      return dayjs(this.order.createdAt).format('YYYY/MM/DD HH:mm:ss')
    },

    orderPaymentConfig () {
      if (!this.order.EcommercePaymentRecord) return null
      return this.order.EcommercePaymentRecord.EcommercePaymentConfig || null
    },

    promoCodeUse () {
      const ecommercePromoCode = this.order.EcommercePromoCodeUse.EcommercePromoCode
      return ecommercePromoCode.code
    },
    rebateStatusOptions () {
      const status = this.formData.rebateStatus
      if (status === 'wait') {
        return [
          { ...rebateStatusConfig.wait, disabled: true },
          { ...rebateStatusConfig.add },
          { ...rebateStatusConfig.cancel, disabled: true },
        ]
      } else if (status === 'add') {
        return [
          { ...rebateStatusConfig.wait, disabled: true },
          { ...rebateStatusConfig.add, disabled: true },
          { ...rebateStatusConfig.cancel },
        ]
      }
      return [
        { ...rebateStatusConfig.wait, disabled: true },
        { ...rebateStatusConfig.add, disabled: true },
        { ...rebateStatusConfig.cancel, disabled: true },
      ]
    },
  },

  async mounted () {
    await this.refresh()
  },

  methods: {
    disabledRefundOptions (status) {
      const curStatus = this.formData.refundedStatus
      if (curStatus.value === 'autoRefunded') {
        if (status === 'autoRefunded') return false
        return true
      } else if (curStatus.value === 'adminRefunded') {
        if (status === 'adminRefunded') return false
        return true
      } else if (curStatus.value === 'request') return false
    },
    async updateRebateStatus () {
      const [res, err] = await UpdatePprocessAddCashback({
        shopId: this.shop,
        id: this.order.id,
        action: this.formData.rebateStatus,
      })
      if (err) return (this.$message.error(err))
      console.log(res)
      await this.refresh()
    },
    async autoInvoice () {
      this.invoiceLoading = true
      await this.updateDeliveryStatus()
      const [, err] = await UpdateInvoiceStatus({
        shopId: this.shop,
        id: this.order.id,
        status: 'systemInvoiced',
      })
      if (err) {
        this.$message.error(err)
        return
      }
      this.$message.success('更新發票狀態成功！')
      this.invoiceLoading = false
      await this.refresh()
    },
    async cancelOrder () {
      const [res, err] = await CancelOrder({
        shopId: this.shop,
        id: this.$route.params.id,
      })
      if (err) return this.$message.error(err)
      console.log(res)
      this.$message.warning('已取消該訂單！')
      await this.refresh()
    },
    availablePaymentStatus () {
      let status = ''
      // const curStatus = this.order.EcommercePaymentRecord.status
      const curStatus = this.formData.paymentStatus?.value
      if (curStatus === 'unpaid') status = 'adminPaid'
      if (curStatus === 'systemPaid') status = 'autoRefunded'
      if (curStatus === 'systemPaid' || curStatus === 'adminPaid') { status = 'adminRefunded' }
      // if (curStatus === 'cancel') status = 'adminPaid'

      return status
    },
    imgSource (image) {
      return imgSrc(this.imgSize, image)
    },
    paymentTypes (type) {
      return paymentConfig[type]
    },
    orderStatusDict (status, cancelBy) {
      let label = ''
      if (status === 'cancel') label = this.cancelByKeysConfig[cancelBy].name
      else label = orderStatusConfig[status].name

      return label
    },
    paymentStatusDict (status) {
      return paymentStatusConfig[status]
    },
    walletStatusDict (wallet) {
      ''
      if (!wallet) return ''
      return orderWalletRecordStatusConfig[wallet.status]
    },

    async walletStatusUpdate () {
      await this.walletRecordRefunded()
    },
    async externalWalletStatusUpdate () {
      await this.externalWalletRecordRefunded()
    },

    async updatePaymentStatus () {
      this.paymentLoading = true
      const origin = this.paymentStatusDict(
        this.order.EcommercePaymentRecord.status,
      )
      const [res, error] = await UpdatePaymentStatus({
        shopId: this.shop,
        id: this.order.id,
        status: this.formData.paymentStatus?.value,
      })

      if (error) {
        this.$message.error(error)
        this.formData.paymentStatus = origin
      }
      if (!error && this.formData.paymentStatus?.value === 'autoRefunded') {
        await this.autoRefunded()
      }
      if (!error) this.$message.success('更新付款狀態成功！')
      this.paymentLoading = false
      await this.refresh()
    },

    async changeDeliveryStatus (status) {
      this.deliveryLoading = true
      if (status === orderDeliveryStatus.shipped) {
        this.shipWarningDialog = true
        this.deliveryLoading = false
        return
      }
      await this.updateDeliveryStatus(status)
      this.deliveryLoading = false
    },

    async updateDeliveryStatus (status) {
      const [res, error] = await UpdateDeliveryStatus({
        shopId: this.shop,
        id: this.$route.params.id,
        status,
      })

      if (error) return this.$message.error(error)
      console.log(res)
      await this.refresh()
    },
    async onDownloadPickupSheet () {
      const [res, error] = await GenerateOrderPickupSheet({
        shopId: this.shop,
        id: this.$route.params.id,
      })
      if (error) return this.$message.error(error)
      const { postFormData, postFormLink } = res.data[0] || {}
      if (!postFormData || !postFormLink) return this.$message.error('沒有寄件單資料')

      const form = CreateFormPost(postFormLink, postFormData, { target: '_blank' })
      document.body.appendChild(form)
      form.submit()
      document.body.removeChild(form)
    },
    async changeRefundedStatus () {
      await this.updateRefundedStatus()
    },

    async updateRefundedStatus () {
      const [res, error] = await UpdateRefundedStatus({
        shopId: this.shop,
        id: this.$route.params.id,
        status: this.formData.refundedStatus.value,
      })
      if (error) return this.$message.error(error)
      this.$message.success('更新退款狀態成功！')
      console.log(res)
      await this.refresh()
    },

    async updateDeliveryInfo (formData) {
      const [res, error] = await UpdateDeliveryInfo({
        shopId: this.shop,
        id: this.$route.params.id,
        code: formData.deliveryCode,
      })

      if (error) this.$message.error(error)
      if (!error) this.$message.success('更新運送編號成功！')
      console.log(res)
      await this.refresh()
      this.showDialog = false
    },

    async autoRefunded () {
      const [res, error] = await AutoRefunded({
        shopId: this.shop,
        id: this.order.id,
      })
      if (error) return this.$message.error(error)
      console.log(res)
    },

    async walletRecordRefunded () {
      const [res, error] = await WalletRecordRefunded({
        shopId: this.shop,
        id: this.order.id,
      })
      if (error) this.$message.error(error)
      console.log(res)
      if (!error) this.$message.success('更新儲值金付款狀態成功！')
      await this.refresh()
    },
    async externalWalletRecordRefunded () {
      const [res, error] = await ExternalWalletRefunded({
        shopId: this.shop,
        id: this.order.id,
      })
      if (error) this.$message.error(error)
      console.log(res)
      if (!error) this.$message.success(`更新${this.externalWalletName}付款狀態成功！`)
      await this.refresh()
    },
    async refresh () {
      const [res, error] = await FindOrder({
        shopId: this.shop,
        id: this.$route.params.id,
        includeCustomFlowRecord: this.useCustomFlowFeature.record,
      })
      this.getCustomFlowConfig()

      if (error) return this.$message.error(error)
      this.order = res

      // 付款狀態
      if (res.EcommercePaymentRecord) {
        this.formData.paymentStatus = this.paymentStatusDict(
          res.EcommercePaymentRecord.status,
        )
      }
      // 回饋金狀態
      if (res.EcommerceOrderAddCashback) {
        const addedAt = res.EcommerceOrderAddCashback.addedAt
        const canceledAt = res.EcommerceOrderAddCashback.canceledAt
        if (!addedAt && !canceledAt) this.formData.rebateStatus = 'wait'
        else if (addedAt && !canceledAt) this.formData.rebateStatus = 'add'
        else if (canceledAt) this.formData.rebateStatus = 'cancel'
      }

      // 運送狀態
      if (res.EcommerceOrderDelivery) {
        this.formData.deliveryStatus = res.EcommerceOrderDelivery.status
      }

      // 儲值金狀態
      this.formData.walletStatus = this.walletStatusDict(
        res.EcommerceOrderWalletRecord,
      ) || { name: '未使用', value: 'unused' }
      this.formData.deliveryCode = res.EcommerceOrderDelivery.code

      this.formData.refundedStatus =
        refundedStatusConfig[res.EcommercePaymentRecord.refundedStatus]

      console.log(
        refundedStatusConfig[res.EcommercePaymentRecord.refundedStatus],
      )
      // 會員餘額狀態
      if (res.externalWalletRefundRecordId !== null) this.formData.externalWalletStatus = { name: '已退款', value: 'refunded' }
      else if (res.externalWalletPaidRecordId !== null) this.formData.externalWalletStatus = { name: '已支付', value: 'paid' }
      else this.formData.externalWalletStatus = { name: '未使用', value: 'unused' }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .status-select {
  width: 150px !important;
  .el-input,
  .el-select {
    width: 150px !important;
  }
}

::v-deep .el-loading-spinner {
  @apply flex justify-center;
}
.order-info-wrapper {
  @apply flex gap-[20px];
  // display: grid;
  // grid-template-columns: repeat(2, 1fr);
  // gap: 20px;
}
.order-info {
  @apply w-full py-[27.5px] px-[20px];
  // width: 100%;
  // padding: 27.5px 20px;
  background: white;

  .item {
    margin-bottom: 15.5px !important;
  }
}

.invoice-info {
  @apply mt-[16px] mb-[44px];
}

section {
  margin: 0;
}

.price-number {
  font-size: 24px;
  color: var(--primary-100);
  font-weight: bold;
}

.edit-btn {
  color: var(--primary-100);
  cursor: pointer;
  text-decoration: underline;

  &.is-disabled {
    color: var(--black-4);
  }
}

.title {
  color: red;
}
</style>
